
body {
    color:#1f2021;
    font-size: 100%;
    font-family: "Yale",serif;
    line-height: 1.25;
    text-rendering: optimizeLegibility;
    padding-right: 0 !important;
}

a {
    color:#1069A0;
    text-decoration: none;
    -webkit-transition: color 0.2s ease-in;
    transition: color 0.2s ease-in;
}

#wrapper {
    max-width: 84rem;
    margin: 0 auto;
    padding: 0 40px 2rem;
}

h1 {
    color:#000440;
    font-weight: 700;
    font-family: "NeueHaasUnicaPro",clean,sans-serif;
    font-size: 2.375rem;
    font-size: calc(2.375rem - 0.875rem + 2vw);
    letter-spacing: -0.1rem;
    line-height: 1.25;
    margin: 2rem 0 1rem;
}

h2 {
    margin: 1rem 0 0.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    font-family: "NeueHaasUnicaPro",clean,sans-serif;
    line-height: 1.25;
}

h2[class*=sub-title] {
    color: #77706a;
    font-size: 1.875rem;
    font-size: calc(1.875rem - 0.875rem + 1vw);
    margin-bottom: 2.5rem;
}

h3 {
    font-size: 0.75rem;
    font-family: "NeueHaasUnicaPro",clean,sans-serif;
    font-weight: 700;
    letter-spacing: 0.125rem;
    line-height: 1.1;
    margin: 1rem 0 0.3125rem;
    text-transform: uppercase;
}

h4,
.delta {
    margin: 1.875rem 0 0.3125rem;
    color: 
    #000440;
    font-family: "NeueHaasUnicaPro",clean,sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25;
}

h5 {
    font-family: "NeueHaasUnicaPro",clean,sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25;
    margin: 1.25rem 0 0.25rem;
}

.icon--magnifier {
    background: none;
    border: none;
    color: #1f2021 !important;
    font-size: 1rem;
    left: 0.3125rem;
    margin: 0;
    padding: 0.1875rem 0.625rem;
    position: absolute;
    text-shadow: none;
    top: 50%;
    font-family: "linearicons" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translatey(-48%);
    transform: translatey(-48%);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

#map {
    width: 100%;
    height: 300px;
}

.form-control { height: auto }

button {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: "Yale",serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25;
    padding: 0.75em 1em;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    vertical-align: middle;
    border-radius: 0.1875rem;
    outline: none;
    background-color: #1069A0;
    border: none;
}

.gift-component.table-bordered td, .table-bordered th {
    border: 0;
}

@media (min-width: 992px) {
    .container {
        max-width: none;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: none;
    }
}


@media (min-width: 576px) {
    .container {
        max-width: none;
    }
}

#navigation--mobile-toggle {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
}

@media (max-width: 44.9375em) {
    #page--header-wrap {
        /*transform: none;
        opacity: 1;*/
        display: none;
        padding: 20px; 
    } 

    #navigation--mobile-toggle {
        display: block;
    }

    img.logo {
        margin: 20px 0 0 20px;
    }
}