#qualtrics-wrapper {
    width: 770px;
    padding: 80px 0;
}

#qualtrics-wrapper label.form-label {
    font-family: "Yale", serif !important;
}

#logo-wrapper {
    padding-bottom: 3em;
}